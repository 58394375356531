<template>
  <div>
    <v-card tile></v-card>
    <div class="card card-custom card-transparent">
      <div v-show="false">
        {{ showNextButton }}
        {{ showNextButtonStep1 }}
        {{ showNextButtonStep2 }}
        {{ duplicate }}
        {{ citizen_valid }}
      </div>
      <div class="card-body p-0">
        <div>
          <v-container v-show="citizenSaveLoading || reservationSaveLoading">
            <v-layout row justify-center align-center>
              <v-progress-circular
                  indeterminate
                  :size="100"
                  :width="7"
                  color="purple"
              ></v-progress-circular>
            </v-layout>
          </v-container>
        </div>
        <div v-show="!citizenSaveLoading">
          <!--begin: Wizard-->
          <div
              class="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
          >
            <!--begin: Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">1</div>
                    <div class="wizard-label">
                      <div class="wizard-title">
                        {{ $t("PAGES.RESERVATIONS.NEW.STEPS.EVENT.TITLE") }}
                      </div>
                      <div class="wizard-desc">
                        {{ $t("PAGES.RESERVATIONS.NEW.STEPS.EVENT.SUB_TITLE") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-number">2</div>
                    <div class="wizard-label">
                      <div class="wizard-title">
                        {{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.TITLE") }}
                      </div>
                      <div class="wizard-desc">
                        {{
                          $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.SUB_TITLE")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-number">3</div>
                    <div class="wizard-label">
                      <div class="wizard-title"> {{ $t("PAGES.RESERVATIONS.NEW.STEPS.COMPLETE.TITLE") }}</div>
                      <div class="wizard-desc"> {{
                          $t("PAGES.RESERVATIONS.NEW.STEPS.COMPLETE.SUB_TITLE")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Nav -->

            <!--begin: Wizard Body-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <div class="card-body p-0">
                <div
                    class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-md-12 col-xxl-7">
                    <!--begin: Wizard Form-->
                    <v-form
                        ref="new_reservation_form"
                        class="form mt-0 mt-lg-10"
                        id="kt_form"
                        v-model="valid"
                        lazy-validation
                    >
                      <!--begin: Wizard Step 1-->
                      <!--begin: Wizard Step 1-->
                      <div
                          class="pb-5"
                          data-wizard-type="step-content"
                          data-wizard-state="current"
                      >
                        <Step1
                            :event.sync="event"
                            :event_date_time.sync="event_date_time"
                            :dose.sync="dose"
                            :eventBusy.sync="eventBusy"
                        ></Step1>
                      </div>
                      <!--begin: Wizard Step 2-->
                      <div class="pb-5" data-wizard-type="step-content">
                        <Step2
                            :citizen.sync="citizen_new"
                            :validateBox.sync="citizen_valid"
                            :event="event"
                            :eventBusy.sync="eventBusy"
                        ></Step2>
                      </div>
                      <!--end: Wizard Step 2-->

                      <!--begin: Wizard Step 3-->
                      <div class="pb-5" data-wizard-type="step-content">
                        <Step3
                            :citizen_step3="citizen_new"
                            :newcitizen="newcitizen"
                            :event="event"
                            :dose="dose"
                            :event_date_time="event_date_time"
                        ></Step3>
                      </div>
                      <!--end: Wizard Step 3-->

                      <!--begin: Wizard Actions -->
                      <div
                          class="d-flex justify-content-between border-top pt-10"
                      >
                        <div class="mr-2">
                          <button
                              class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                              data-wizard-type="action-prev"
                          >
                            {{ $t("COMMON.BUTTON.PREV_STEP") }}
                          </button>
                        </div>
                        <div>
                          <button
                              v-on:click="submit"
                              class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                              data-wizard-type="action-submit"
                          >
                            {{ $t("COMMON.BUTTON.CONFIRM_RESERVATION") }}
                          </button>
                          <button
                              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                              :class="!showNextButton ? 'orange' : ''"
                              @click="showAlertNextButton"
                              data-wizard-type="action-next"
                          >
                            {{ $t("COMMON.BUTTON.NEXT_STEP") }}
                          </button>
                        </div>
                      </div>
                      <!--end: Wizard Actions -->
                    </v-form>
                    <!--end: Wizard Form-->
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Bpdy-->
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
@import "~vuetify/dist/vuetify.css";
</style>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import {mapGetters} from "vuex";
import Step1 from "@/view/pages/events/wizard/Step1";
import Step2 from "@/view/pages/events/wizard/Step2";
import Step3 from "@/view/pages/events/wizard/Step3";
// import {CREATE_RESERVATIONS_DATA} from "@/core/services/store/reservations.module";
import {CREATE_CITIZEN_DATA} from "@/core/services/store/citizens.module";

import Vue from "vue";
import {VueReCaptcha} from "vue-recaptcha-v3";
import {CREATE_RESERVATIONS_DATA} from "@/core/services/store/reservations.module";
import {LOGIN, VERIFY_AUTH} from "@/core/services/store/auth.module";
import {SET_BASE_INFO} from "@/core/services/store/profile.module";

Vue.use(VueReCaptcha, { siteKey: window.__env.captcha.key })

export default {
  data() {
    return {
      eventBusy: false,
      citizenSaving: false,
      reservationSaving: false,
      citizen_valid: false,
      duplicate: false,
      wizardTmp: KTWizard,
      citizen_new_tmp: {
        name: "asd",
        surname: "dsa",
        fiscal_code: "asddsa80t45f133w",
        date_of_birth: "1980-12-26",
        selectedCityBirth: 7387,
        selectedCityResidence: 7387,
        residence_address: "Via allora, 4",
        residence_postal_code: "09012",
        phone: 987654321,
        mobile_phone: 13245678,
        email: "virgi.micale@gmail.com",
        password: "111111111",
        selectedCountryBirth: 12,
        selectedCountryResidence: 28
      },
      event: {},
      dose: {},
      event_date_time: {},
      newcitizen: false,
      valid: true,
      singleItemLoading: false,
      isLoadingCities: false,
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1
      },
      creationData: {
        dose: Number,
        reservation_date_time: String,
        citizen: Number,
        event: Number
      },
      citizen_new: {}
    };
  },
  name: "newReservation",
  computed: {
    ...mapGetters([
      "getCitizenSavingSuccess",
      "countriesList",
      "eventsList",
      "getReservationSavingSuccess",
      "getCitizenSaved",
      "getCitizenApiErrors",
      "citizenSaveLoading",
      "getReservationApiErrors",
      "reservationSaveLoading",
      "currentUserBaseInfo",
      "getReservationSaved",
      "isAuthenticated"
    ]),
    events_found() {
      return Object.assign([], this.eventsList.data);
    },
    showNextButton() {
      // return (!this.showNextButtonStep1 || (!this.showNextButtonStep2 && this.duplicate)) ? false : true;
      if (this.wizardTmp instanceof KTWizard) {
        if (
            !this.showNextButtonStep1 && this.wizardTmp.getStep() === 1 ||
            !this.showNextButtonStep2 && this.wizardTmp.getStep() === 2) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    showNextButtonStep1() {
      return (this.event.id == undefined || this.event_date_time.date == undefined || this.dose.id == undefined || this.eventBusy === true) ? false : true;
    },
    showNextButtonStep2() {
      return (this.citizen_valid && this.eventBusy !== true) ? true : false;
    },
    showSaveUserButton() {
      if (this.citizen_new.id == undefined && this.wizardTmp.currentStep == 1) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.citizen_new = {}
    if (this.isAuthenticated === false) {
      this.$store.commit(SET_BASE_INFO, {});
    }
    this.$store.dispatch(VERIFY_AUTH);
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("PAGES.RESERVATIONS.AREA")},
      {title: this.$t("PAGES.RESERVATIONS.NEW.TITLE")}
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });
    this.wizardTmp = wizard;
    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    let contex = this;
    wizard.on("change", function (/*wizardObj*/) {
      // contex.$emit("update:citizen_step2", this.citizen_new);
      contex.$emit("update:citizen_step3", this.citizen_new);
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
    showSwal() {
      Swal.fire({
        title: "",
        text: this.$t("COMMON.WIZARD.ALERT.COMPLETE_ALL_FIELDS"),
        icon: "error",
        confirmButtonClass: "btn btn-secondary"
      });
    },
    errorSwal(title, message) {
      Swal.fire({
        title: title,
        text: message,
        icon: "error",
        confirmButtonClass: "btn btn-secondary"
      });
    },
    showAlertNextButton(e) {
      switch (this.wizardTmp.getStep()) {
        case 1:
          if (!this.showNextButtonStep1) {
            e.preventDefault();
            this.showSwal()
          } else {
            this.wizardTmp.goTo(1)
          }
          break;
        case 2:
          if (!this.showNextButtonStep2) {
            e.preventDefault();
            this.showSwal()
            this.wizardTmp.stop()
          } else {
            this.wizardTmp.goTo(2)
          }
          break;
        case 3:

          break;
      }

    },
    validate() {
      if (this.$refs.new_reservation_form.validate()) {
        this.elaborateForeign();
        this.save();
      }
    },
    submit: function (e) {
      e.preventDefault();
      // if (this.getReservationSavingSuccess !== false) {
      if (
          this.citizen_new.id === undefined &&
          this.isAuthenticated === false
      ) {
        let citizen_to_create = Object.assign({}, this.citizen_new);
        if (citizen_to_create.selectedCountryBirth !== 380) {
          citizen_to_create.bornAbroad = citizen_to_create.selectedCountryBirth
          citizen_to_create.city_of_birth = null;
          this.$delete(citizen_to_create, 'selectedCountryBirth');
          this.$delete(citizen_to_create, 'selectedCityBirth');
        } else {
          citizen_to_create.city_of_birth = citizen_to_create.selectedCityBirth;
          this.$delete(citizen_to_create, 'selectedCountryBirth');
        }
        if (citizen_to_create.selectedCountryResidence !== 380) {
          citizen_to_create.residenceAbroad = citizen_to_create.selectedCountryResidence
          citizen_to_create.domicileAbroad = citizen_to_create.selectedCountryResidence
          citizen_to_create.city_of_residence = null;
          citizen_to_create.city_of_domicile = null;
          this.$delete(citizen_to_create, 'selectedCountryResidence');
          this.$delete(citizen_to_create, 'selectedCityResidence');
        } else {
          citizen_to_create.city_of_residence = citizen_to_create.selectedCityResidence;
          citizen_to_create.city_of_domicile = citizen_to_create.selectedCityResidence;
          this.$delete(citizen_to_create, 'selectedCountryResidence');
        }
        this.$delete(citizen_to_create, 'selectedCountryBirth');
        this.$delete(citizen_to_create, 'selectedRegionBirth');
        this.$delete(citizen_to_create, 'selectedStateBirth');
        this.$delete(citizen_to_create, 'selectedCityBirth');
        this.$delete(citizen_to_create, 'selectedCountryResidence');
        this.$delete(citizen_to_create, 'selectedRegionResidence');
        this.$delete(citizen_to_create, 'selectedStateResidence');
        this.$delete(citizen_to_create, 'selectedCityResidence');
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("registration").then((token) => {
            citizen_to_create.captcha = token
            this.saveCitizen(citizen_to_create);
          });
        });
      } else {
        this.saveReservation()
      }
      // }
    },
    async saveCitizen(citizen_to_create) {
      this.citizenSaving = true;
      await this.$store.dispatch(CREATE_CITIZEN_DATA, citizen_to_create);
    },
    async saveReservation() {
      this.reservationSaving = true;
      let reservation_to_create = {};
      reservation_to_create.event = this.event.id
      reservation_to_create.dose = this.dose.id
      reservation_to_create.status = 1
      reservation_to_create.reservation_date_time = this.event_date_time.date
      reservation_to_create.citizen = this.getCitizenSaved.data !== undefined ? this.getCitizenSaved.data.id : this.currentUserBaseInfo.id
      await this.$store.dispatch(CREATE_RESERVATIONS_DATA, reservation_to_create);
    },
    convertDate(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      });
    }
  },
  watch: {
    showNextButtonStep1() {
      this.citizen_new = (this.currentUserBaseInfo !== undefined && this.currentUserBaseInfo.id !== undefined) ? this.currentUserBaseInfo : {};
    },
    currentUserBaseInfo() {
      this.citizen_new = (this.currentUserBaseInfo !== undefined && this.currentUserBaseInfo.id !== undefined) ? this.currentUserBaseInfo : {};
    },
    eventBusy() {
      if (this.eventBusy === true && (this.getReservationSavingSuccess === false && this.reservationSaveLoading === false)) {
        this.errorSwal(this.$t("COMMON.WIZARD.ALERT.EVENT_BUSY_BY_USER_TITLE"), this.$t("COMMON.WIZARD.ALERT.EVENT_BUSY_BY_USER"))
      }
    },
    getReservationApiErrors() {
      if (this.getCitizenApiErrors !== null) {
        this.errorSwal(this.getReservationApiErrors, this.$t("COMMON.WIZARD.ALERT.RESERVATION_CREATION_ERROR"))
      }
    },
    getCitizenApiErrors() {
      if (this.getCitizenApiErrors !== null) {
        this.errorSwal(this.getCitizenApiErrors, this.$t("COMMON.WIZARD.ALERT.USER_CREATION_ERROR"))
      }
    },
    getCitizenSaved() {
      if (
          this.getCitizenSavingSuccess === true &&
          this.citizenSaving == true &&
          this.getCitizenSaved.data.id !== undefined &&
          this.getCitizenApiErrors === null
      ) {
        let username = this.citizen_new.fiscal_code;
        let password = this.citizen_new.password;
        let context = this;
        this.citizenSaving = false;
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("login").then((token) => {
            context.$store
                .dispatch(LOGIN, {username, password, token})
                .then(function () {
                  context.saveReservation();
                });
          });
        });
      }
    },
    citizen_new() {
      this.$emit("update:citizen_step2", this.citizen_new);
      this.$emit("update:citizen_step3", this.citizen_new);
    },
    getReservationSavingSuccess() {

      if (this.getReservationSavingSuccess) {
        Swal.fire({
          title: "",
          text: this.$t("PAGES.RESERVATIONS.NEW.TITLE_SUCCESS"),
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        }).then(() => {
              this.singleItem = {};
              this.$router.push({name: "resume_reservation", params: {code: this.getReservationSaved.code, r: 1}})
            }
        );
      } else {
        this.errorSwal(this.getReservationApiErrors, this.$t("PAGES.RESERVATIONS.NEW.TITLE_ERROR"))
      }
    }
  },
  components: {
    Step1,
    Step2,
    Step3
  }
};
</script>
