<template>
  <div>
    <v-container>
      <v-toolbar-title
      >{{
          !logged ?
              $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.AUTHENTICATE_OR_CREATE_NEW_ONE") :
              $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.USER_LOGGED")
        }}
      </v-toolbar-title>
      <v-row>
        <v-col cols="12" md="4" sm="12" v-show="!logged">
          <v-card :loading="isLoggingLoading">
            <BoxLogin
                :redirect="false"
            ></BoxLogin>
          </v-card>
        </v-col>
        <v-col cols="12" :md="logged?12:8" sm="12">
          <BoxRegistration
              :citizen.sync="citizen"
              :validateBox.sync="validateBox"
              :editable="true"
          ></BoxRegistration>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
@import "~vuetify/dist/vuetify.css";
</style>

<script>
import {mapGetters} from "vuex";
import BoxLogin from "@/view/commons/BoxLogin";
import BoxRegistration from "@/view/commons/BoxRegistration";
import {rulesMixin} from "@/core/mixin/rules.mixin";
import Vue from "vue";
import {VueReCaptcha} from "vue-recaptcha-v3";
import {FETCH_RESERVATIONS_CHECK} from "@/core/services/store/reservations.module";

Vue.use(VueReCaptcha, {siteKey: "6LdZLo4UAAAAAJ78awvAYLVjskijxdJ0A-sMOkWe"});

export default {
  data() {
    return {
      validateBox: false,
      userLogged: false,
      btnColor: "btn-warning",
      button_text: this.$t("COMMON.BUTTON.VALIDATE_USER_DATA"),
      disabled: false,
      citizen_init: {
        date_of_birth: "01/01/1980",
      },
      selectedCountryBirth: 380,
      selectedRegionBirth: 0,
      selectedStateBirth: 0,
      selectedCityBirth: 0,
      isLoadingCountries: 380,
      selectedCountryResidence: 380,
      selectedRegionResidence: 0,
      selectedStateResidence: 0,
      selectedCityResidence: 0,
      citizenSelected: {
        hidden: true,
        city_of_birth: {},
        city_of_residence: {},
      },
      filtersFiscalCode: {
        query: [],
        filters: {
          fiscalCode: {
            _eq: {},
          },
        },
        sort: [],
        page: 1,
        limit: 1,
      },
      filters_reservations: {
        query: [],
        filters: {
          "citizen.code": {_eq: {}},
          "event.code": {_eq: ""},
        },
        sort: [
          {
            direction: "DESC",
            field: "id",
          },
        ],
        page: 1,
        limit: 10,
      },
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
      }
    };
  },
  mixins: [rulesMixin],
  props: {
    citizen: {},
    event: {},
    valid: Boolean,
    eventBusy: Boolean
  },
  name: "step1ReservationWizard",
  mounted() {
    if(this.currentUserBaseInfo === undefined || this.currentUserBaseInfo.id === undefined){
      this.citizen = {}
    }
  },
  computed: {
    ...mapGetters(["countriesList", "getCitizenSavingSuccess", "getCitizenSaved", "isLoggingLoading", "currentUserBaseInfo", "reservationInCheck", "isAuthenticated"]),
    logged() {
      if (this.currentUserBaseInfo !== undefined && this.currentUserBaseInfo.id !== undefined) {
        this.$emit("update:valid", true);
        return true;
      }
      this.$emit("update:valid", false);
      return false;
    },
    citizen_block() {
      return this.citizenSelected != undefined &&
      this.citizenSelected.id != undefined
          ? true
          : false;
    }
  },
  methods: {},
  watch: {
    reservationInCheck() {
      this.eventBusy = (this.reservationInCheck !== undefined && this.reservationInCheck[0] !== undefined) ? true : false;
      this.$emit("update:eventBusy", this.eventBusy);
    },
    currentUserBaseInfo() {
      this.filters_reservations.filters["citizen.code"]._eq = this.citizen.code
      this.filters_reservations.filters["event.code"]._eq = this.event.code
      if (this.citizen.fiscal_code !== undefined) {
        this.$store.dispatch(FETCH_RESERVATIONS_CHECK, this.filters_reservations);
      }
    },
    validateBox() {
      this.$emit("update:validateBox", this.validateBox);
    },
    citizen() {
      this.$emit("update:citizen", this.citizen);
    },
    citizenSelected() {
      this.$emit("update:citizen", this.citizenSelected);
      this.$emit("update:newcitizen", false);
    },
    getCitizenSavingSuccess() {
      if (this.getCitizenSavingSuccess == true) {
        if (this.citizenSelected.id != undefined) {
          this.$emit("update:newcitizen", true);
        } else {
          this.$emit("update:newcitizen", false);
        }
        this.$emit("update:citizen", this.getCitizenSaved.data);
      }
      this.citizenSelected.id = this.getCitizenSaved.id;
      this.button_text = this.$t("COMMON.BUTTON.SAVED_USER");
      this.disabled = true;
    },
  },
  components: {
    BoxLogin,
    BoxRegistration
  }
};
</script>
