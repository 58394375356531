<template>
  <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white "
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
  >
    <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7"
    >
      <div class="form-group">
        <!--begin::Signin-->
        <div class="login-form login-signin">
          <v-form
              ref="kt_login_signin_form"
              v-model="valid"
              lazy-validation
          >
            <div class="pb-13 pt-lg-0 pt-5">
              <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
              >
                {{ $t("AUTH.LOGIN.TITLE") }}
              </h3>
              <p class="text-muted font-weight-bold font-size-h4 text-danger" v-show="error">
                {{ $t("AUTH.LOGIN.CREDENTIALS_ERROR") }}
              </p>
            </div>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                    name="username"
                    ref="username"
                    :rules="requiredRules"
                    v-model="login.username"
                    color="blue-grey lighten-2"
                    dense
                    filled
                    :label="
                              $t('PAGES.CITIZENS.FORM.MANAGE.FISCAL_CODE')
                            "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                    name="password"
                    ref="password"
                    v-model="login.password"
                    color="blue-grey lighten-2"
                    :label="$t('FORM.PASSWORD')"
                    dense
                    filled
                    :rules="requiredRules"
                    @click:append="show1 = !show1"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                ></v-text-field>
                <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    v-if="active_forgot_password"
                >{{ $t("FORM.FORGOT_PASSWORD") }}</a
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="pb-lg-0 pb-5">
                  <button
                      ref="kt_login_signin_submit"
                      class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                      @click="recaptcha"
                  >
                    {{ $t("FORM.LOGIN") }}
                  </button>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <!--end::Signin-->
        <!--begin::Forgot-->
        <div class="login-form login-forgot" v-show="active_forgot_password">
          <!--begin::Form-->
          <v-form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
          >
            <div class="pb-13 pt-lg-0 pt-5">
              <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
              >
                {{ $t("AUTH.FORGOT.TITLE") }}
              </h3>
              <p class="text-muted font-weight-bold font-size-h4">
                {{ $t("AUTH.FORGOT.DESCRIPTION") }}
              </p>
            </div>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                    name="fiscalCode"
                    ref="fiscalCode"
                    v-model="fiscalCode"
                    color="blue-grey lighten-2"
                    :label="$t('PAGES.CITIZENS.FORM.MANAGE.FISCAL_CODE')"
                    dense
                    filled
                    :rules="requiredRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="form-group d-flex flex-wrap pb-lg-0">
              <button
                  type="button"
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  @click="recaptchaForgot"
              >
                {{ $t("AUTH.FORGOT.BUTTON") }}
              </button>
              <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
              >
                Cancel
              </button>
            </div>
          </v-form>
        </div>
        <!--end::Forgot-->
      </div>

    </div>
  </div>
</template>
<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>
<script>
import Vue from "vue";
import {rulesMixin} from "@/core/mixin/rules.mixin";
import {VueReCaptcha} from "vue-recaptcha-v3";
import {FORGOT_PASSWORD, LOGIN, LOGOUT} from "@/core/services/store/auth.module";
import {mapGetters} from "vuex";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import KTUtil from "@/assets/js/components/util";
import axios from 'axios'
import Swal from "sweetalert2";

Vue.use(VueReCaptcha, { siteKey: window.__env.captcha.key })

export default {
  name: "BoxLogin",
  data() {
    return {
      state: "signin",
      fiscalCode: '',
      error: false,
      loaderEnabled: true,
      show1: false,
      userLogged: false,
      active_forgot_password: true,
      login: {
        username: "",
        password: "",
      }
    }
  },
  props: {
    redirect: Boolean
  },
  mixins: [rulesMixin],
  computed: {
    ...mapGetters(["isLoggingLoading", "isAuthenticated", "layoutConfig", "getLoginError"]),
    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    }
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
          KTUtil.getById(form_name),
          "animate__animated animate__backInUp"
      );
    },
    recaptcha(event) {
      event.preventDefault()
      var username = this.login.username;
      var password = this.login.password;
      var context = this;
      const submitButton = this.$refs.kt_login_signin_submit;
      if (this.$refs.kt_login_signin_form.validate()) {
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("login").then((token) => {
            // clear existing errors
            axios.post(window.__env.api_captcha.url, {captcha: token})
                .then(() => {
                  context.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                  context.$store.dispatch(LOGOUT);
                  setTimeout(() => {
                    // send login request
                    context.$store
                        .dispatch(LOGIN, {username, password, token});

                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                    context.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                  }, 2000);// Will print the token
                }).catch(() => {
              this.showErrorSwal(this.$t("LOGIN.ERROR.CAPTCHA"));
            });
          });
        });
      }
    },
    recaptchaForgot(event) {
      event.preventDefault()
      var fiscalCode = this.fiscalCode;
      var context = this;
      if (this.$refs.kt_login_forgot_form.validate()) {
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("forgot_password").then((token) => {
            // clear existing errors
            context.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            setTimeout(() => {
              // send login request
              context.$store
                  .dispatch(FORGOT_PASSWORD, {fiscalCode, token})
                  .then(function () {
                    context.error = false;
                    context.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    if (context.redirect == true) {
                      context.$router.push({name: "list_event_page"});
                    }
                  })
                  .catch(() => {
                    context.error = true;
                    context.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                  });
              context.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }, 2000);// Will print the token
          });
        });
      }
    },
    showErrorSwal(message) {
      Swal.fire({
        title: "",
        text: message,
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      if (this.redirect == true) {
        this.$router.push({name: "list_event_page"});
      }
    }
  },
  watch: {
    isAuthenticated(){
      if(this.redirect == true) {
        this.$router.push({name: "list_event_page"});
      }
    },
    error() {
      if (this.error === true) {
        this.showErrorSwal(this.$t("LOGIN.ERROR.ACCESS"));
      }
    },
    getLoginError() {
      if (this.getLoginError === true) {
        this.showErrorSwal(this.$t("LOGIN.ERROR.ACCESS"));
      }
    }
    // isAuthenticated() {
    //   this.$router.push({ name: "list_event_page" });
    // }
  },
  components: {}
}

</script>
