<template>
  <div>
    <v-container>
      <v-toolbar-title
        >{{ $t("PAGES.RESERVATIONS.NEW.STEPS.EVENT.SEARCH_OPEN_EVENT") }}
      </v-toolbar-title>
      <v-row>
        <v-col cols="12" md="6">
          <div class="form-group">
            <v-autocomplete
              v-model="event_selected"
              :items="events_found"
              :loading="isLoadingEventsSearch"
              :search-input.sync="events_search"
              filled
              color="blue-grey lighten-2"
              :label="$t('PAGES.RESERVATIONS.NEW.STEPS.EVENT.HELPTEXT')"
              :placeholder="$t('PAGES.RESERVATIONS.NEW.STEPS.EVENT.SEARCH')"
              :filter="customEventsFilter"
              return-object
              @change="eventChange"
              clearable
            >
              <template slot="selection" slot-scope="{ item, tile }">
                {{ convertDate(item.start_date) }} - {{ item.title }}
                {{ item.city.denomination }}
                {{ tile }}
              </template>
              <template slot="item" slot-scope="data">
                {{ convertDate(data.item.start_date) }} -
                {{ data.item.title }} -
                {{ data.item.city.denomination }}
              </template>
            </v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" md="6" v-show="hideParts">
          <div class="card card-custom card-stretch">
            <div class="card-body pt-4">
              <div class="py-2">
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.TITLE") }}:</span
                  >
                  <a class="text-muted text-hover-primary">{{
                    event_selected.title
                  }}</a>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.CODE") }}:</span
                  >
                  <a class="text-muted text-hover-primary">{{
                    event_selected.code
                  }}</a>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.LOCALITY") }}:</span
                  >
                  <a class="text-muted text-hover-primary">{{
                    city.denomination
                  }}</a>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.ADDRESS") }}:</span
                  >
                  <a class="text-muted text-hover-primary">{{
                    event_selected.address
                  }}</a>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.BEGIN_DATE") }}:</span
                  >
                  <a class="text-muted text-hover-primary">{{
                    new Date(event_selected.start_date).toLocaleString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}</a>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.END_DATE") }}:</span
                  >
                  <a class="text-muted text-hover-primary">{{
                    new Date(event_selected.end_date).toLocaleString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-divider v-show="hideParts"></v-divider>
      <v-row v-show="hideParts">
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="date_time_selected"
            :items="availabilities_found"
            :loading="isLoadingAvailabilitiesSearch"
            filled
            color="blue-grey lighten-2"
            :label="
              $t('PAGES.RESERVATIONS.NEW.STEPS.EVENT.SEARCH_TIME_OPEN_EVENT')
            "
            return-object
            @change="dateTimeChange"
            clearable
          >
            <template slot="selection" slot-scope="{ item, tile }">
              {{ convertDateTime(item.date) }}
              {{ tile }}
            </template>
            <template slot="item" slot-scope="data">
              {{ convertDateTime(data.item.date) }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="dose_selected"
            :items="dosesActiveList"
            :loading="isLoadingDosesSearch"
            hide-no-data
            hide-selected
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.RESERVATIONS.NEW.STEPS.EVENT.DOSE')"
            return-object
            @change="dosesChange"
            clearable
          >
            <template slot="selection" slot-scope="{ item, tile }">
              {{ item.title }} {{ tile }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.title }}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
@import "~vuetify/dist/vuetify.css";
</style>
<script>
import { FETCH_EVENTS_LIST } from "@/core/services/store/events.module";
import { mapGetters } from "vuex";
import { GET_AVAILABILITY_BY_ID } from "@/core/services/store/availabilities.module";
// import {FETCH_RESERVATIONS_LIST} from "@/core/services/store/reservations.module";
import Swal from "sweetalert2";
import moment from "moment";
import {FETCH_RESERVATIONS_CHECK} from "@/core/services/store/reservations.module";

export default {
  name: "step2ReservationWizard",
  data() {
    return {
      events_search: {},
      event_selected: {},
      date_time_selected: {},
      events_filters: {
        query: [],
        filters: {
          _f1: {
            _cmb_or: "_code,_title,_description,_city_denomination",
          },
          _f2: {
            _cmb_and: "_start_date,_end_date",
          },
          _code: {
            code: {
              _like: "%",
            },
          },
          _title: {
            title: {
              _like: "%",
            },
          },
          _description: {
            description: {
              _like: "%",
            },
          },
          _city_denomination: {
            "city.denomination": {
              _like: "%",
            },
          },
          _start_date: {
            startDate: {
              _gt: this.todayDate(),
            },
          },
          _end_date: {
            endDate: {
              _gt: this.todayDate(),
            },
          },
          _cmb_and: "_f1,_f2",
        },
        sort: {},
      },
      filters_reservations: {
        query: [],
        filters: {
          "citizen.fiscalCode": { _eq: {} },
          "event.id": { _eq: "" },
        },
        sort: [
          {
            direction: "DESC",
            field: "id",
          },
        ],
        page: 1,
        limit: 10,
      },
      filters_reservations_in_check: {
        query: [],
        filters: {
          "citizen.code": {_eq: {}},
          "event.code": {_eq: ""},
          "status": 1
        },
        sort: [
          {
            direction: "DESC",
            field: "id",
          },
        ],
        page: 1,
        limit: 10,
      },
    };
  },
  props: {
    event: {},
    dose: {},
    event_date_time: {},
    citizen: {},
    eventBusy: Boolean
  },
  computed: {
    city: function () {
      return this.event_selected.city != undefined
        ? this.event_selected.city
        : {};
    },
    ...mapGetters([
      "countriesList",
      "eventsList",
      "availabilityInUse",
      "reservationsList",
      "reservationInCheck",
      "currentUserBaseInfo"
    ]),
    events_found() {
      return Object.assign([], this.eventsList.data);
    },
    availabilities_found() {
      return Object.assign([], this.availabilityInUse);
    },
    hideParts() {
      return this.event_selected.id != undefined ? true : false;
    },
    dosesActiveList: function () {
      return Object.assign([], this.event_selected.doses);
    },
    duplicate: function () {
      return this.reservationsList.meta != undefined &&
        this.reservationsList.meta.pagination.count > 0
        ? true
        : false;
    },
  },
  methods: {
    todayDate() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    customEventsFilter: function (item, queryText) {
      var title = item.title.toLowerCase();
      var code = item.code.toLowerCase();
      var description = item.description.toLowerCase();
      var address = item.address.toLowerCase();
      var city_denomination = item.city.denomination.toLowerCase();
      var start_date = item.start_date.toLowerCase();
      var searchText = queryText.toLowerCase();

      return (
        title.indexOf(searchText) > -1 ||
        code.indexOf(searchText) > -1 ||
        address.indexOf(searchText) > -1 ||
        city_denomination.indexOf(searchText) > -1 ||
        start_date.indexOf(searchText) > -1 ||
        description.indexOf(searchText) > -1
      );
    },
    eventChange: function (e) {
      if (e == null) {
        this.event_selected = {};
      } else {
        // this.filters_reservations.filters["citizen.fiscalCode"]._eq =
        //   this.citizen.fiscal_code;
        this.filters_reservations.filters["event.id"]._eq =
          this.event_selected.id;
        this.$store.dispatch(GET_AVAILABILITY_BY_ID, this.event_selected.id);
        if (this.currentUserBaseInfo !== undefined && this.currentUserBaseInfo.fiscal_code !== undefined) {
          this.filters_reservations_in_check.filters["citizen.code"]._eq = this.currentUserBaseInfo.code
          this.filters_reservations_in_check.filters["event.code"]._eq = this.event_selected.code
          this.$store.dispatch(FETCH_RESERVATIONS_CHECK, this.filters_reservations_in_check);
        }
      }
    },
    dateTimeChange: function (e) {
      if (e == null) {
        this.date_time_selected = {};
      }
      this.$emit("update:event", this.event_selected);
      this.$emit("update:event_date_time", this.date_time_selected);
    },
    dosesChange: function (e) {
      if (e == null) {
        this.dose_selected = {};
      }
      this.$emit("update:dose", this.dose_selected);
    },
    convertDate(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    },
    convertDateTime(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
    citizenEventError() {
      this.$emit("update:duplicate", true);
      Swal.fire({
        title: "",
        text: this.$t("COMMON.WIZARD.ALERT.EVENT_RESERVATION_DUPLICATED"),
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
      });
    },
  },
  mounted() {
    this.$store.dispatch(FETCH_EVENTS_LIST, this.events_filters);
  },
  watch: {
    reservationInCheck() {
      this.eventBusy = (this.reservationInCheck !== undefined && this.reservationInCheck[0] !== undefined) ? true : false;
      this.$emit("update:eventBusy", this.eventBusy);
    },
    events_found() {
      this.isLoadingEventsSearch = false;
    },
    events_search(val) {
      // Items have already been loaded
      if (this.eventsList.length > 0) return;
      if (val == null) return;
      // Items have already been requested
      if (this.isLoadingEventsSearch) return;
      this.isLoadingEventsSearch = true;

      this.events_filters.filters._code.code._like = "%" + val + "%";
      this.events_filters.filters._title.title._like = "%" + val + "%";
      this.events_filters.filters._description.description._like =
        "%" + val + "%";
      this.events_filters.filters._city_denomination[
        "city.denomination"
      ]._like = "%" + val + "%";
      this.$store.dispatch(FETCH_EVENTS_LIST, this.events_filters);
    },
    duplicate() {
      if (this.duplicate) {
        this.citizenEventError();
      } else {
        this.$emit("update:duplicate", false);
      }
    },
  },
};
</script>
