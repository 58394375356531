<template>
  <div class="card card-custom card-stretch">
    <div class="card-body pt-4">
      <v-container>
        <v-form ref="new_citizen_form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                  v-model="citizen.name"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.NAME')"
                  color="blue-grey lighten-2"
                  :rules="requiredRules"
                  dense
                  filled
                  ret
                  :disabled="isAuthenticated"
                  @keypress="changed()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                  v-model="citizen.surname"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.SURNAME')"
                  :rules="requiredRules"
                  color="blue-grey lighten-2"
                  dense
                  filled
                  ret
                  :disabled="isAuthenticated"
                  @keypress="changed()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                  v-model="citizen.fiscal_code"
                  :rules="requiredRules"
                  ref="fiscal_code_field"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.FISCAL_CODE')"
                  color="blue-grey lighten-2"
                  dense
                  filled
                  @keypress="changed()"
                  :error-messages="fiscalCodeCheckMessage"
                  :error="fiscalCodeError"
                  :disabled="isAuthenticated"
                  v-on:blur="checkFiscalCode"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-toolbar-title
          >{{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.BIRTH_DATA") }}
          </v-toolbar-title>
          <v-row>
            <v-col cols="12" md="6">
              <DateBox
                  :initial-date="citizen.date_of_birth"
                  :final-date.sync="citizen.date_of_birth"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.DATE_OF_BIRTH')"
                  :max="todayDate()"
                  @keypress="changed()"
                  :disabled="isAuthenticated"
              ></DateBox>
            </v-col>
          </v-row>
          <LocationBoxes
              :countriesList="countriesList"
              :selectedCity.sync="citizen.selectedCityBirth"
              :selectedCountry.sync="citizen.selectedCountryBirth"
              :selectedRegion.sync="citizen.selectedRegionBirth"
              :selectedState.sync="citizen.selectedStateBirth"
              :disabled="isAuthenticated"
              :resident="false"
              :valid="true"
              :changed.sync="changedBirth"
          ></LocationBoxes>
          <v-row>
            <v-col cols="12" md="12">
              <v-divider></v-divider>
              <v-toolbar-title
              >{{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.RESIDENCE_DATA") }}
              </v-toolbar-title>
            </v-col>
          </v-row>
          <LocationBoxesResidence
              :copyable="false"
              :countries-list="countriesList"
              :selectedCity.sync="citizen.selectedCityResidence"
              :selectedCountry.sync="citizen.selectedCountryResidence"
              :selectedRegion.sync="citizen.selectedRegionResidence"
              :selectedState.sync="citizen.selectedStateResidence"
              :disabled="isAuthenticated"
              :valid="true"
              :changed.sync="changedResidence"
          ></LocationBoxesResidence>
          <v-row>
            <v-col cols="12" md="10">
              <v-text-field
                  v-model="citizen.residence_address"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.ADDRESS')"
                  color="blue-grey lighten-2"
                  dense
                  filled
                  :disabled="isAuthenticated"
                  ret
                  @keypress="changed()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                  v-model="citizen.residence_postal_code"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.ZIP')"
                  color="blue-grey lighten-2"
                  dense
                  :disabled="isAuthenticated"
                  filled
                  @keypress="changed()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="citizen.phone"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.PHONE')"
                  color="blue-grey lighten-2"
                  dense
                  :disabled="isAuthenticated"
                  @keypress="
                  isNumber($event);
                  changed();
                "
                  filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="citizen.mobile_phone"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.MOBILE_PHONE')"
                  color="blue-grey lighten-2"
                  dense
                  :disabled="isAuthenticated"
                  @keypress="
                  isNumber($event);
                  changed();
                "
                  filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="citizen.email"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.EMAIL')"
                  color="blue-grey lighten-2"
                  :rules="emailRules"
                  dense
                  filled
                  :error="emailError"
                  :disabled="isAuthenticated"
                  @keypress="changed()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-show="!isAuthenticated">
              <v-text-field
                  v-model="reemail"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.CONFIRM_EMAIL')"
                  color="blue-grey lighten-2"
                  :rules="emailRules"
                  dense
                  filled
                  :error="emailError"
                  :disabled="isAuthenticated"
                  @keypress="changed()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-toolbar-title v-show="!isAuthenticated"
          >{{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.PASSWORD") }}
          </v-toolbar-title>
          <v-row v-show="!isAuthenticated">
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="citizen.password"
                  :label="$t('PAGES.USERS.DIALOG.MANAGE.PASSWORD')"
                  color="blue-grey lighten-2"
                  dense
                  :rules="requiredPasswordRules"
                  @keypress="changed()"
                  @click:append="show1 = !show1"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  counter
                  :error="passwordError"
                  :disabled="isAuthenticated"
                  hint="At least 8 characters"
                  filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="repassword"
                  :label="$t('PAGES.USERS.DIALOG.MANAGE.PASSWORD')"
                  color="blue-grey lighten-2"
                  dense
                  :rules="requiredPasswordRules"
                  @keypress="changed()"
                  @click:append="show2 = !show2"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  :error="passwordError"
                  :disabled="isAuthenticated"
                  counter
                  hint="At least 8 characters"
                  filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="3"
            >
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="privacy"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                  :error="checkPrivacy"
                  size="lg"
                  class="custom-control-inlin"
              >
                {{ $t('PAGES.CITIZENS.FORM.MANAGE.PRIVACY_ACCEPT') }}
              </b-form-checkbox>
              <a
                  :href="customConfig.footer.child.privacy"
                  target="_blank"
                  class="nav-link pr-3 pl-0"
              >{{ $t('PAGES.CITIZENS.FORM.MANAGE.SHOW_PRIVACY') }}</a
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" >
              <button
                  class="btn font-weight-bold text-uppercase px-9 py-4 mr-1"
                  :class="btnColor"
                  @click="validate"
              >
                {{ button_text }}
              </button>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </div>
</template>

<script>
import {rulesMixin} from "@/core/mixin/rules.mixin";
import {FETCH_COUNTRIES_LIST} from "@/core/services/store/countries.module";
import {FETCH_FISCAL_CODE_CHECK} from "@/core/services/store/citizens.module";
import {mapGetters} from "vuex";
import moment from "moment";
import DateBox from "@/view/commons/forms/DateBox";
import LocationBoxes from "@/view/commons/forms/LocationBoxes";
import LocationBoxesResidence from "@/view/commons/forms/LocationBoxesResidence";
import Swal from "sweetalert2";

export default {
  name: "BoxRegistration",
  data() {
    return {
      privacy: false,
      checkPrivacy: false,
      changedBirth: false,
      changedResidence: false,
      passwordError: false,
      emailError: false,
      show1: false,
      show2: false,
      validLocationBirth: true,
      validLocationResidence: true,
      fiscalCodeCheckMessage: false,
      userLogged: false,
      btnColor: "btn-warning",
      button_text: this.$t("COMMON.BUTTON.VALIDATE_USER_DATA"),
      disabled: false,
      valid: false,
      citizen_init: {
        date_of_birth: "01/01/1980"
      },
      isLoadingCountries: false,
      citizenSelected: {
        hidden: true,
        city_of_birth: {},
        city_of_residence: {}
      },
      filtersFiscalCode: {
        query: [],
        filters: {
          fiscalCode: {
            _eq: {}
          }
        },
        sort: [],
        page: 1,
        limit: 1
      },
      empty_filters: {
        query: [],
        filters: {},
        sort: {}
      },
      repassword: "",
      reemail: "",
      fiscalCodeError: false
    };
  },
  mixins: [rulesMixin],
  props: {
    citizen: {},
    validateBox: {},
    editable: Boolean
  },
  methods: {
    enableValidateButton() {
      this.button_text = this.$t("COMMON.BUTTON.USER_DATA_VALIDATED");
      this.btnColor = "btn-success";
      this.$emit("update:validateBox", true);

    },
    initLocations() {
      if (
          this.citizen.city_of_birth !== undefined &&
          this.citizen.city_of_birth.id !== undefined
      ) {
        this.citizen.selectedCityBirth = this.citizen.city_of_birth.id;
        this.citizen.selectedStateBirth = this.citizen.city_of_birth.state.id;
        this.citizen.selectedRegionBirth =
            this.citizen.city_of_birth.state.region.id;
        this.citizen.selectedCountryBirth =
            this.citizen.city_of_birth.state.region.country.id;
      }
      if (
          this.citizen.city_of_residence !== undefined &&
          this.citizen.city_of_residence.id !== undefined
      ) {
        this.citizen.selectedCityResidence = this.citizen.city_of_residence.id;
        this.citizen.selectedStateResidence =
            this.citizen.city_of_residence.state.id;
        this.citizen.selectedRegionResidence =
            this.citizen.city_of_residence.state.region.id;
        this.citizen.selectedCountryResidence =
            this.citizen.city_of_residence.state.region.country.id;
      }
    },
    errorSwal(title, message) {
      Swal.fire({
        title: title,
        text: message,
        icon: "error",
        confirmButtonClass: "btn btn-secondary"
      });
    },
    checkLocationBoxes() {
      this.validLocationBirth = true;
      this.changedBirth = false;
      this.changedResidence = false;
      if (this.citizen.selectedCountryBirth === undefined) {
        this.validLocationBirth = true;
        // this.validLocationBirth = false;
      }
      if (this.citizen.selectedCountryBirth === 380) {
        if (
            this.citizen.selectedCityBirth === undefined ||
            this.citizen.selectedCityBirth === null
        ) {
          this.validLocationBirth = true;
          // this.validLocationBirth = false;
        }
      }
      this.validLocationResidence = true;
      if (this.citizen.selectedCountryResidence === undefined) {
        this.validLocationResidence = true;
        // this.validLocationResidence = false;
      }
      if (this.citizen.selectedCountryResidence === 380) {
        if (
            this.citizen.selectedCityResidence === undefined ||
            this.citizen.selectedCityResidence === null
        ) {
          this.validLocationResidence = true;
          // this.validLocationResidence = false;
        }
      }
    },
    changed() {
      if (!this.isAuthenticated) {
        this.button_text = this.$t("COMMON.BUTTON.VALIDATE_USER_DATA");
        this.btnColor = "btn-warning";
        this.valid = false;
        this.$emit("update:validateBox", false);
      }
    },
    checkFiscalCode() {
      // if (this.citizen.fiscal_code.length == 16) {
      this.$store.dispatch(FETCH_FISCAL_CODE_CHECK, this.citizen.fiscal_code);
      // } else {
      //   this.fiscalCodeCheckMessage = false;
      // }
    },
    validate(evt) {
      evt.preventDefault();
      if (this.$refs.new_citizen_form.validate()) {
        if (this.checkPrivacy) {
          this.errorSwal(this.$t("PAGES.CITIZENS.FORM.MANAGE.PRIVACY_SWAL_TITLE"), this.$t("PAGES.CITIZENS.FORM.MANAGE.PRIVACY_SWAL_MESSAGE"))
          return;
        }
        if (!this.isAuthenticated) {
          this.citizen.city_of_domicile = this.citizen.city_of_residence;
          this.citizen.domicile_address = this.citizen.residence_address;
          this.citizen.domicile_postal_code = this.citizen.residence_postal_code;
          this.checkPassword();
          this.checkEmail();
          this.checkLocationBoxes();
          if (
              false ||
              this.validLocationBirth == false ||
              this.validLocationResidence == false ||
              this.passwordError !== false
          ) {
            this.changed();
          } else {
            this.button_text = this.$t("COMMON.BUTTON.USER_DATA_VALIDATED");
            this.btnColor = "btn-success";
            this.$emit("update:validateBox", true);
          }
        } else {
          this.$emit("update:validateBox", false);
        }
      } else {
        this.$emit("update:validateBox", true);
      }
    },
    checkPassword() {
      return this.repassword != this.citizen.password ? true : false;
    },
    checkEmail() {
      return this.reemail !== this.citizen.email ? true : false;
    },

    todayDate() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    convertDate(value, box = false) {
      return !box
          ? moment(new Date(value)).format("DD-MM-YYYY")
          : moment(new Date(value)).format("YYYY-MM-DD");
    },
    checkCityOfBirth() {
      this.selectedCityBirth =
          this.currentUserBaseInfo !== undefined &&
          this.currentUserBaseInfo.city_of_birth != undefined
              ? this.currentUserBaseInfo.city_of_birth.id
              : "";
      this.selectedStateBirth =
          this.currentUserBaseInfo !== undefined &&
          this.currentUserBaseInfo.city_of_birth.state != undefined
              ? this.currentUserBaseInfo.city_of_birth.state.id
              : "";
      this.selectedRegionBirth =
          this.currentUserBaseInfo !== undefined &&
          this.currentUserBaseInfo.city_of_birth.state != undefined
              ? this.currentUserBaseInfo.city_of_birth.state.region.id
              : "";
      this.selectedCountryBirth =
          this.currentUserBaseInfo !== undefined &&
          this.currentUserBaseInfo.city_of_birth.state != undefined
              ? this.currentUserBaseInfo.city_of_birth.state.region.country.id
              : "";
    },

    checkCityOfResidence() {
      this.selectedCityResidence =
          this.currentUserBaseInfo !== undefined &&
          this.currentUserBaseInfo.city_of_residence != undefined
              ? this.currentUserBaseInfo.city_of_residence.id
              : "";
      this.selectedStateResidence =
          this.currentUserBaseInfo !== undefined &&
          this.currentUserBaseInfo.city_of_residence.state != undefined
              ? this.currentUserBaseInfo.city_of_residence.state.id
              : "";
      this.selectedRegionResidence =
          this.currentUserBaseInfo !== undefined &&
          this.currentUserBaseInfo.city_of_residence.state != undefined
              ? this.currentUserBaseInfo.city_of_residence.state.region.id
              : "";
      this.selectedCountryResidence =
          this.currentUserBaseInfo !== undefined &&
          this.currentUserBaseInfo.city_of_residence.state != undefined
              ? this.currentUserBaseInfo.city_of_residence.state.region.country.id
              : "";
    }
  },
  mounted() {
    this.$store.dispatch(FETCH_COUNTRIES_LIST, this.empty_filters);
    this.initLocations();
    if (this.isAuthenticated) {
      this.enableValidateButton()
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "countriesList",
      "getSavingSuccess",
      "getCitizenSaved",
      "currentUserBaseInfo",
      "getFiscalCodeChecked",
      "customConfig"
    ]),
    selectedCountryBirth() {
      return this.citizen.selectedCountryBirth;
    },
    selectedCountryResidence() {
      return this.citizen.selectedCityResidence;
    },
    citizen_block() {
      return this.citizenSelected != undefined &&
      this.citizenSelected.id != undefined
          ? true
          : false;
    },
    fiscalCodeUsed() {
      if (
          this.citizenInCheck != undefined &&
          this.citizenInCheck[0] != undefined
      ) {
        return this.citizenInCheck[0].fiscal_code.toLowerCase() ==
        this.citizen.fiscal_code.toLowerCase()
            ? true
            : false;
      }
      return false;
    }
  },
  watch: {
    isAuthenticated() {
      this.initLocations()
    },
    changedBirth() {
      if (this.changedBirth) {
        this.changed();
      }
    },
    changedResidence() {
      if (this.changedResidence) {
        this.changed();
      }
    },
    citizen() {
      if (this.isAuthenticated) {
        this.button_text = this.$t("COMMON.BUTTON.USER_DATA_VALIDATED");
        this.btnColor = "btn-success";
        this.$emit("update:validateBox", true);
      }
      if (
          this.citizen.city_of_birth !== undefined &&
          this.citizen.city_of_birth.id !== undefined
      ) {
        this.citizen.selectedCityBirth = this.citizen.city_of_birth.id;
        if(this.citizen.city_of_birth.state !== undefined){
          this.citizen.selectedStateBirth = this.citizen.city_of_birth.state.id;
          this.citizen.selectedRegionBirth =
              this.citizen.city_of_birth.state.region.id;
          this.citizen.selectedCountryBirth =
              this.citizen.city_of_birth.state.region.country.id;
        }
      }
      if (
          this.citizen.city_of_residence !== undefined &&
          this.citizen.city_of_residence.id !== undefined
      ) {
        this.citizen.selectedCityResidence = this.citizen.city_of_residence.id;
        if(this.citizen.city_of_residence.state !== undefined) {
          this.citizen.selectedStateResidence =
              this.citizen.city_of_residence.state.id;
          this.citizen.selectedRegionResidence =
              this.citizen.city_of_residence.state.region.id;
          this.citizen.selectedCountryResidence =
              this.citizen.city_of_residence.state.region.country.id;
        }
      }
      this.$emit("update:citizen", this.citizen);
    },
    currentUserBaseInfo() {
      var user =
          this.currentUserBaseInfo != undefined &&
          this.currentUserBaseInfo.id != undefined
              ? Object.assign({}, this.currentUserBaseInfo)
              : Object.assign({}, this.citizen_init);
      user.date_of_birth = this.convertDate(user.date_of_birth, true);
      this.checkCityOfBirth();
      this.checkCityOfResidence();
      // this.city_of_birth = user.city_of_birth!=undefined? user.city_of_birth.id:''
      this.citizen = user;
    },
    getFiscalCodeChecked() {
      this.fiscalCodeCheckMessage =
          this.getFiscalCodeChecked.used != undefined
              ? this.$t("PAGES.USERS.ATTRIBUTES.FISCAL_CODE_BUSY")
              : false;
      this.fiscalCodeError =
          this.getFiscalCodeChecked.status !== undefined &&
          this.getFiscalCodeChecked.status === false
              ? true
              : false;
    },
    citizenSelected() {
      this.$emit("update:citizen", this.citizenSelected);
      this.$emit("update:newcitizen", false);
    }
  },
  components: {
    LocationBoxes,
    DateBox,
    LocationBoxesResidence
  }
};
</script>
